<template>
  <div class="register">
    <div class="register-cont">
      <!-- <img
        class="register-cont-title"
        src="../assets/register/register_title.png"
        alt=""
      /> -->
      <div class="register-cont-cont">
        <!-- <div class="rcc-register">注册</div> -->
        <el-form
          disabled
          :model="ruleForm"
          ref="ruleForm"
          label-width="160px"
          class="rcc-ruleForm"
        >
          <el-form-item label="12309账号：" prop="accountNumber">
            <el-col :span="16"
              ><el-input v-model="ruleForm.accountNumber"></el-input
            ></el-col>
          </el-form-item>
          <el-form-item label="检察院名称：" prop="jcyName">
            <el-col :span="16">
              <el-input v-model="ruleForm.jcyName"></el-input>
              <!-- <el-input v-model="ruleForm.jcyName"></el-input> -->
            </el-col>
          </el-form-item>
          <el-form-item
            label="接收短信的手机号："
            prop="mobile"
            class="display:flex"
          >
            <el-col :span="16"
              ><el-input v-model="ruleForm.mobile"></el-input
            ></el-col>
            <el-col
              :span="8"
              style="
                font-size: calc(100vw * 12 / 1920);
                padding-left: calc(100vw * 10 / 1920);
              "
              >多个手机号中间以英文“,”隔开</el-col
            >
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import cross from "../utils/cross";
import imgUrl from "../../public/avatar.png";
import Vue from "vue";
import qs from "qs";
import axios from "axios";

export default {
  name: "Register",
  data() {
    return {
      codeUrl: "",
      ruleForm: {
        jcyName: "",
        mobile: "",
        sendingStartTime: "",
        sendingStartTime: "",
        accountNumber: "",
        password: "",
        jcyId: "",
      },
      options: [],
      isLoading: false,
      loading: false,
      // 验证码开关
      captchaEnabled: true,
      // 注册开关
      register: false,
      redirect: undefined,
    };
  },
  watch: {
    $route: {
      handler: function (route) {
        this.redirect = route.query && route.query.redirect;
      },
      immediate: true,
    },
  },
  created() {
    // 从 localStorage 中读取字符串
    const userString = localStorage.getItem("user");

    // 使用 JSON.parse() 将字符串转换回对象
    const user = JSON.parse(userString);
    this.ruleForm = user;
    // this.initTreeFun();
  },
  methods: {},
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.register {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  .register-cont {
    display: flex;
    flex-direction: column;
    // justify-content: center;
    // align-items: center;
    width: calc(100vw * 1030 / 1920);
    height: calc(100vh * 854 / 1080);
    margin-top: calc(100vh * 20 / 1080);
    .register-cont-title {
      width: calc(100vw * 1000 / 1920);
      margin-bottom: calc(100vh * 40 / 1080);
    }
    .register-cont-cont {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: calc(100vw * 1070 / 1920);
      height: calc(100vh * 632 / 1080);
      // min-width: 1070px;
      // min-height: 445px;
      // background-color: #fff;
      // border-radius: calc(100vw * 20 / 1920);
      // box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.1);
      .rcc-register {
        font-size: 20px;
        margin-bottom: calc(100vh * 30 / 1080);
      }
      .rcc-ruleForm {
        width: calc(100vw * 800 / 1920);
      }
    }
  }
}
</style>
